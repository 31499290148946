import React from "react";
import {CarCatalogDataInfo} from "../../../types/CatalogTypes";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {Link, useNavigate} from "react-router-dom";
import {setCatalogFilter} from "../../../store/reducers/catalogFilterSlice";
import Utils from "../../../utils/Utils";
import {CarPreorderButton} from "../CarPreorderButton";
import {CarTag, tags} from "../CarTag/CarTag";
import {CarRentDataInfo} from "../CardTypes";
import ImageDoesNotExist from "../ImageDoesNotExists/ImageDoesNotExists";

const CarCard: React.FC<{
    car: CarCatalogDataInfo,
    id?: string;
}> = ({car, id}) => {
    // const dispatch = useAppDispatch();
    // const filter = useAppSelector((state) => state.catalogFilter);
    const navigate = useNavigate();
    // const updateSameCarFilter = (value: string) => {
    //     dispatch(setCatalogFilter({...filter, models: [value]}));
    // };

    return (
        <div
            className="car__card anim-start-top-4 anim-duration-1800"
            id={id}
        >
            <Link to={`/catalog/${car.id}`} style={{ textDecoration: "none"}} className={`${car.image ? "car__card-image" : ""}`}>
                {
                    car.image
                        ? <img src={car.image} alt={car.brand + " " + car.model} />
                        :
                            <div className={"car__card--car-does-not-exists-wrapper"}>
                                <ImageDoesNotExist responsible={false} />
                            </div>
                }
            </Link>
            <div className="car__card-description">
                <div className="car__card-taglist">
                    { tags.map((i, index) => (
                            <CarTag adaptive={false} key={index} car={car}>
                                {i}
                            </CarTag>
                        ))
                    }
                </div>
                <div
                    onClick={() => navigate(`/catalog/${car.id}`)}
                    className="car__card-title"
                    title={`${car.brand} ${car.model}`}
                >
                    {car.brand} <span className={"model"}>{car.model}</span>
                </div>
                <div className={'car__card-regnum'}>
                    {car.regnum}
                </div>
                <div className="car__card-devider"></div>

                <div className="car__card-payment">
                    от &nbsp;
                    <span className={"car__card-payment-value"}>
                        {Utils.numberWithSpaces(car.min_pay)} ₽
                    </span>
                    &nbsp; / сут
                </div>
                <div
                    className="car__card-price"
                >
                    Цена от&nbsp;
                    <span
                        className="car__card-price-value"
                    >
						{Utils.numberWithSpaces(car.price)} ₽
					</span>
                </div>
                {/*<CarSameLink*/}
                {/*	responsive={responsive}*/}
                {/*	ncar={car}*/}
                {/*	className={"mb-px-30 font-weight-semibold"}*/}
                {/*	onClick={() => updateSameCarFilter(car.model_id)}*/}
                {/*/>*/}
                <div className="car__card-preorder">
                    <CarPreorderButton car={car as CarCatalogDataInfo} />
                </div>
            </div>
        </div>
    );
};
export default CarCard;