import { ToastContainer, toast } from 'react-toastify';
import * as S from './AppToast.styles'
import {createPortal} from "react-dom";
import icon from './icon-error.svg';
import 'react-toastify/dist/ReactToastify.css';
import { injectStyle } from "react-toastify/dist/inject-style";

type RunToast = {
    firstMessage: any,
    secondMessage: any,
    toastId: string
}
if (typeof window !== "undefined") {
    injectStyle();
  }
export const runErrorToast = ({
                                    firstMessage,
                                    secondMessage,
                                    toastId
                                }: RunToast) => {

    return toast.error(<div>
        <S.ToastMessageFirst>{firstMessage}</S.ToastMessageFirst>
        <S.ToastMessageSecond>{secondMessage}</S.ToastMessageSecond>
    </div>, {
        toastId,
        icon: <img src={icon} alt='icon' />
    })
}


const AppErrorToast = () => {
    return createPortal(
        <S.ToastContainerStyled error={true} hideProgressBar position={"bottom-center"} autoClose={3000} />
        , document.body)
}

export default AppErrorToast;