import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactHTMLElement } from "react";
import "./PersonalAccountCalendarButton.scss";

const PersonalAccountCalendarButton = ({ children }: { children: any }) => {
  return <div className={"personal-account_calendar"}>{children}</div>;
};

export default PersonalAccountCalendarButton;
