import React, {useState} from "react";
import {Carousel} from "react-bootstrap";

import "./CustomCarousel.scss";
import {TypeImages} from "../../pages/Rent/RentCarImagesCarousel";
import ImageDoesNotExist from "../ImageDoesNotExists/ImageDoesNotExists";
import classNames from "classnames";

type CustomCarousel = {
    images: TypeImages[],
    className?: string | undefined;
}

const CustomCarousel: React.FC<CustomCarousel> = ({images, className}) => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };

    const containerClasses = classNames({
        "customCarousel": true,
        [className || ""]: Boolean(className),
    })

    return (
        <div className={containerClasses}>
            <Carousel controls={images.length > 1} indicators={false} activeIndex={index} onSelect={handleSelect}>
                {images.map((item) => {
                    return (
                        <Carousel.Item key={item.id}>
                            {item.image
                                ? <img className={"customCarouselImage"} src={item.image} alt="car image"/>
                                : <div className={"customCarouselImageDoesNotExistsWrapper"}><ImageDoesNotExist /></div>
                            }
                        </Carousel.Item>
                    )
                })}
            </Carousel>

            {images.length > 1 && (
                <div className={"CarouselBottomLibrary"}>
                    {
                        images.map((item, i) =>
                            <img
                                onClick={() => handleSelect(i)}
                                key={item.id}
                                src={item.image}
                                alt="car image"
                            />
                        )
                    }
                </div>
            )}
        </div>
    )
}

export default CustomCarousel;