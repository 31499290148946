import React, { useEffect, useState } from "react";
import PersonalAccountLayout from "../../layout/PersonalAccountLayout/PersonalAccountLayout";
import { Col, Container, Row } from "react-bootstrap";
import { SmallFooter } from "../../layout/Footer";
import PersonalAccountMenu from "./PersonalAccountMenu";
import PersonalAccountData from "./PersonalAccountData";
import PersonalAccountForm from "./PersonalAccountForm/PersonalAccountForm";
import PersonalAccountSocials from "./PersonalAccountSocials/PersonalAccountSocials";
import { useOutside } from "../../../hooks/useOutside";
import PersonalAccountMenuMobile from "./PersonalAccountMenuMobile";
import PersonalAccountMenuBurger from "../../common/PersonalAccount/PersonalAccountMenuBurger/PersonalAccountMenuBurger";
import PersonalAccountBalance from "./PersonalAccountBalance/PersonalAccountBalance";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import Header from "../../layout/Header";
import MobileMenu from "../../layout/MobileMenu";
import { runSuccessToast } from "../../common/AppToast/AppToast";
import { runErrorToast } from "../../common/AppToast/AppErrorToast";
import api from "../../../core/axios";
import Utils from "../../../utils/Utils";

export interface userManagerProps {
  first_name: string;
  middle_name: string;
  last_name: string;
  phone: string;
  email: string;
  social: { type: string; url: string }[];
}

export interface userDataProps {
  name: string;
  last_name: string;
  middle_name: string;
  phone: string;
  email: string;
  birth_date: string;
  manager: userManagerProps;
  tg_connected: boolean;
}

export const userData: userDataProps = {
	name: "",
	last_name: "",
	middle_name: "",
	phone: "+7 (999) 999 99 99",
	email: "",
	birth_date: "2014-01-01",
	manager: {
		first_name: "Владимир",
		middle_name: "Владимирович",
		last_name: "Грешкин",
		phone: "8 (812) 317 68 15",
		email: "",
		social: [
		//   {
		//     type: "vk",
		//     url: "#",
		//   },
		//   {
		//     type: "wp",
		//     url: "#",
		//   },
		{
			type: "tg",
			url: "https://t.me/AvtoparkVoshod",
		},
		],
	},
	tg_connected: false,
};

const PersonalAccountPage: React.FC = () => {
  const [currentUserData, setCurrentUserData] =
    useState<userDataProps>(userData);
  // for menu personal account
  const { ref, isShow, setIsShow } = useOutside(false);
  const navigate = useNavigate();
  const { isAuthenticated, last_name, first_name, phone } =
    useAuth();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pid = queryParams.get("pid");
  const checkPayment = async () => {
    const token = localStorage.getItem("accessToken")
    try {
      const check = await api.get(
        `/voshod-auto/?w=balance_check&pid=${pid}`,
        {
          headers: token ? {
            Authorization: `Bearer ${token}`
          } : {},
          withCredentials: true,
        }
      );
	  console.log(check);
      if (check.data.data.status === "CONFIRMED") {
        console.log("CONFIRMED");
        runSuccessToast({
          firstMessage: <span>Операция прошла успешно!</span>,
          secondMessage: <span>+{check.data.data.amount}  {'>'} {check.data.account_to_name}</span>,
          toastId: check.data.data.amount,
        });
      }
      if (check.data.data.status === "CANCELED") {
        console.log("CANCELED");
        runErrorToast({
          firstMessage: <span>Ошибка при проведении операции!</span>,
          secondMessage: <span>{check.data.data.amount}₽  Платёж отменён</span>,
          toastId: check.data.data.amount,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    } else if (last_name && first_name) {
      const updateUserDate = {
        ...currentUserData,
        last_name: last_name,
        name: first_name,
        phone: phone,
      };
      setCurrentUserData(updateUserDate);
    }
    if (pid) {
      checkPayment();
    }
  }, []);

  return (
    <>
      <PersonalAccountLayout mainMobileMenu={setIsShow}>
        <Header
          burgerMenuIsShow={isShow}
          setBurgerMenuIsShow={setIsShow}
          type={"white"}
          selectedLink={"/"}
          mobileModalType="orderCall"
        />
        <Container fluid={"xxl"}>
          <Row>
            <Col className={"d-none d-lg-block"} lg={2}>
              <PersonalAccountMenu selected="/personal-account" />
            </Col>
            <Col lg={10}>
              <div className="personal-account_page">
                <div className="personal-account_head d-flex align-items-end justify-content-between">
                  <PersonalAccountData data={currentUserData} />
                  <PersonalAccountBalance />
                  {/* <PersonalAccountCards /> */}
                </div>
                <div className="personal-account_page_body">
                  <PersonalAccountForm data={currentUserData} />
                  <PersonalAccountSocials
                    data={userData.manager}
                    tg_connected={userData.tg_connected}
                  />
                </div>
                <SmallFooter className="d-none d-lg-block" />
                <div className="d-block d-lg-none personal-account_footer">
                  ООО ВОСХОДⓒ 2023 год
                </div>
                {/* <PersonalAccountMenuBurger onClick={() => setIsShow(!isShow)} /> */}
              </div>
            </Col>
          </Row>
        </Container>
      </PersonalAccountLayout>
      <MobileMenu menuRef={ref} setMenuIsOpen={setIsShow} menuIsOpen={isShow} />
    </>
  );
};

export default PersonalAccountPage;
