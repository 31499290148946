import React from "react";
import {CarCatalogDataInfo} from "../../types/CatalogTypes";
import CarBookingForm from "./CarBookingForm";

export const CarPreorderButton: React.FC<{
    car: CarCatalogDataInfo;
    style?: React.CSSProperties;
    className?: string;
    w100?: boolean;
}> = ({car, style, className, w100 = true}) => {
    return (
        <div>
            <CarBookingForm car={car} wide={w100}/>
        </div>
    );
};