import React, {CSSProperties, FC} from "react";
import CarDoesNotExistsSVG from "../../../images/common/car-does-not-exists.svg";

import styles from "./ImageDoesNotExists.module.scss";
import classNames from "classnames";

type ImageDoesNotExists = {
    responsible?: boolean,
}

const ImageDoesNotExists: FC<ImageDoesNotExists> = ({ responsible = true }) => {

    const containerStyles =  classNames({
            [styles.carImageDoesNotExists]: true,
            [styles.carImageDoesNotExistsResponsible]: responsible
    })

    return <div className={containerStyles}>
        <img className={styles.carImageDoesNotExistsImg} src={CarDoesNotExistsSVG} alt="no car"/>
        <div className={styles.carImageDoesNotExistsText}>
            Фото временно <br />
            отсутствует
        </div>
    </div>
};

export default ImageDoesNotExists;