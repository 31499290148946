import api from "../../core/axios";

class RentHistoryService {
  getCars() {
    const token = localStorage.getItem("accessToken");
    return api
      .get("/voshod-auto/?w=history-rent", {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
        withCredentials: true,
      })
      .then((res) => res.data);
  }
}

export default new RentHistoryService();
