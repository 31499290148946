import React, { useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import Loader from "../../common/Loader";
import LoadError from "../../common/LoadError";
import Paginator from "../../common/Paginator";
import { useQuery } from "@tanstack/react-query";
import catalogService from "../../../api-functions/catalog-page/catalog-service";
import { MobileModal } from "../../common/MobileModal/MobileModal";
import {Col, Container, Row} from "react-bootstrap";
import CarCard from "../../common/CarCard/CarCard";
import {CarCatalogDataInfo} from "../../../types/CatalogTypes";
import CarCardMobile from "../../common/CarCard/CarCardMobile";

const CarGrid: React.FC<{ loader?: () => void }> = (props) => {
	const [activePage, setActivePage] = useState("1");
	const [carFormModalMobile, setCarFormModalMobile] = useState(false);
	const filter = useAppSelector((state) => state.catalogFilter);
	const { data, error, isLoading } = useQuery({
		queryKey: ["rent-cars", { activePage, ...filter }],
		queryFn: () => catalogService.getCars(activePage, filter),
	});

	if (isLoading) return <Loader />;
	if (error) return <LoadError response={data} />;
	if (data.list.length === 0)
		return (
			<div
				className={"d-flex w-100 text-center text-muted align-items-center"}
				style={{ minHeight: "60vh" }}>
				<em>Поиск по выбранным Вами параметрам не вернул результатов</em>
			</div>
		);
	return (
		<>
			<div>
				<Row className="mx-0" xxl={3} xs={2}>
					{!isLoading &&
						data.list.map((car: CarCatalogDataInfo, i: number) => (
							<Col className="justify-content-center px-0" key={i}>
								<CarCardMobile type="installment" car={car} key={car.id}/>
							</Col>
						))}
				</Row>
				<div className={"catalog__grid-paginator"}>
					<Paginator setActive={setActivePage} data={data}/>
				</div>
			</div>
			<MobileModal
				active={carFormModalMobile}
				setActive={setCarFormModalMobile}
			/>
		</>
	);
};

export default CarGrid;
