import { useEffect, useState } from "react";
import Utils from "../../../../../utils/Utils";
import { cardsData } from "../../PersonalAccountWithdraw/mobile/PersonalAccountWithdraw";
import { MobileModal } from "../../../MobileModal/MobileModal";
import { ModalTemplateInput } from "../../../ModalFormTemplate";
import CardSelect from "../../../CardSelect/CardSelect";
import { HeaderLogoImage } from "../../../../layout/Header";
import back from "../../../../../images/common/back-dark.svg";
import call from "../../../../../images/common/Phone-header-dark.svg";
import sbp from "../../../../../images/personal-account/transactions/sbp.png";
import AccountSelect from "../../../AccountSelect/AccountSelect";
import { useQuery } from "@tanstack/react-query";
import balanceService from "../../../../../api-functions/balance/balance-service";
import sbplogo from "../../../../../images/personal-account/transactions/sbplogo.png";
import card from "../../../../../images/personal-account/transactions/card.png";
import { runErrorToast, runSuccessToast } from "../../../AppToast/AppToast";
import api from "../../../../../core/axios";
import { isMobile } from "react-device-detect";
import { SbpButton } from "../../../SbpButton/SbpButton";

const ReplenishMobile: React.FC<{
    active: boolean,
    setActive: (e: { opened: boolean, type: string }) => void,
    setCallActive: (e: boolean) => void,
}> = (props) => {
    const { active, setActive, setCallActive } = props;

    const [payload, setPayload] = useState({
        sum: 0,
        type: "sbp",
        from: {
            name: cardsData.cards[0].name,
            number: cardsData.cards[0].number
        },
        to: {
            name: "",
            icon: "",
            balance: 0
        },
        errors: {},
    axiosError: "",
    });
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);

    const { data, isLoading } = useQuery({
        queryKey: ['accounts'],
        queryFn: () => balanceService.getBalance()
    });

    const setFromValue = (value: { name: string, number: string }) => {
        let errors = payload.errors;
        delete errors["from"];
        let newData = {
            ...payload, from: {
                name: value.name,
                number: value.number
            }, errors: errors
        };
        setPayload(newData);
        // errors = Utils.validateWithdraw(payload, data);
    }

    const setToValue = (value: { name: string, icon: string, balance: number }) => {
        let errors = payload.errors;
        delete errors["to"];
        let newData = {
            ...payload, to: {
                name: value.name,
                icon: value.icon,
                balance: value.balance
            }
        };
        setPayload(newData);
    }

    const validateData = (field: string, value: string) => {
        let errors = payload.errors;
        delete errors[field];
        delete errors["server"];
        let newData = { ...payload, [field]: value, errors: errors };
        setPayload(newData);
        // errors = Utils.validateWithdraw(data, balance);
    }

    const setPaymentType = (type: string) => {
        let errors = payload.errors;
        delete errors["server"];
        setPayload(prev => ({ ...prev, type: type }));
        if (type === "card") {
            setPayload(prev => ({ ...prev, from: cardsData.cards[0], errors: errors }));
        }
    }

    const setServerError = (error: string) => {
        let errors = payload.errors;
        delete errors["server"];

        setPayload(prev => ({
            ...prev,
            errors: {
                ...errors,
                server: error
            }
        }));
    }

  const [isQrOpen, setIsQrOpen] = useState<boolean>(false);
  const [qrUrl, setQrUrl] = useState();
  const [isSbpOpen, setIsSbpOpen] = useState<boolean>(false)

  interface AxiosError {
    code: string;
    response: {
      data: {
        result: number;
        message: string;
      };
    };
  }

  const [redirectSpbLink, setRedirectSpbLink] = useState<string>('')

    const send = async () => {
        let errors = Utils.validateReplenish(payload);
        if (Object.keys(errors).length > 0) {
            setPayload({ ...payload, errors: errors });
            return;
        }
    try {
      // сумма с комиссией
      const res = await api.get(
        `/voshod-auto/?w=balance_up&summ=${payload.sum}&payment=${
          payload.type === "card" ? "classic" : "sbp"}&device=${isMobile ? "mobile" : "desktop"}`,
        {
          withCredentials: true,
        }
      );
      // если все ок при оплате картой
      if (res.data.result === 1 && payload.type === "card") {
        window.location.assign(res.data.redirect)
        return;
      }
      // если все ок при сбп
      if (res.data.result === 1 && payload.type === "sbp") {
        if (isMobile) {
            setRedirectSpbLink(res.data.redirect)
            setIsSbpOpen(true)
            return
        } else {
        // ставим url для qr кода
        setQrUrl(res.data.qr);
        // открываем qr
        setIsQrOpen(true);
        }
        // каждые две секунды проверяем оплату по pid
        const checkPayment = setInterval(async () => {
          const check = await api.get(
            `https://test.taxivoshod.ru/api/voshod-auto/?w=balance_check&pid=${res.data.pid}`,
            {
              withCredentials: true,
            }
          );
          if (check.data.data.status === "CONFIRMED") {
            console.log("CONFIRMED");
            runSuccessToast({
              firstMessage: <span>Операция прошла успешно!</span>,
              secondMessage: (
                <span>
                  +{payload.sum}₽ {">"} {check.data.data.account_to_name}
                </span>
              ),
              toastId: payload.sum.toString(),
            });
            clearInterval(checkPayment);
            setActive({
                opened: false,
                type: "",
            });
            return;
          }
          if (check.data.data.status === "CANCELED") {
            console.log("CANCELED");
            runErrorToast({
              firstMessage: <span>Ошибка при проведении операции!</span>,
              secondMessage: (
                <span>
                  <s>+{payload.sum}₽</s>Платёж отменён
                </span>
              ),
              toastId: payload.sum.toString(),
            });
            clearInterval(checkPayment);
            return;
          }
        }, 2000);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError!.response.data.message);
      setPayload({ ...payload, axiosError: axiosError!.response.data.message });
    }
    }

    return (
        <>
            <div className={`balance-mobile  ${active && "active"}`}>
                <div className="balance-mobile_head">
                    <div className="mobile-modal_header-top">
                        <img src={back} onClick={() => setActive({ opened: false, type: "" })} alt="" />
                        <HeaderLogoImage width={"100px"} height={"24px"} image="dark" />
                        <img src={call} alt="" onClick={() => {
                            setActive({ opened: false, type: "replenish" });
                            setCallActive(true);
                        }} />
                    </div>
                </div>
                <div className="balance-mobile_body">
                    <div className="call-content-text-header mb-px-8 font-size-24">
                        Пополнить СЧЁТ
                    </div>
          {isQrOpen && qrUrl ? (
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(qrUrl!)}`}
              alt="qr"
              width={200}
              height={200}
            />
          ) : (
            <>
                    <p className="font-size-12 mb-px-32">
                        Введите необходимую сумму для пополнения <br />и выберите способ
                        оплаты
                    </p>
              <div className="mb-px-15 mt-px-15">
                <ModalTemplateInput
                  error={payload.errors["sum"]}
                  type={"number"}
                  small={false}
                  placeholder="Введите сумму"
                  onChange={(e: any) => validateData("sum", e.target.value)}
                />
                {payload.errors["server"] && (
                  <div className={"my-2 text-red-color font-size-12"}>
                    {payload.errors["server"]}
                  </div>
                )}
              </div>
              <div>
                <div className="mb-px-25">
                  <div className="replenish_types column">
                    <div
                      className={
                        "replenish_type " +
                        (payload.type === "sbp" ? "active" : "")
                      }
                      onClick={() => setPaymentType("sbp")}
                    >
                      <img src={sbplogo} alt="СБП" className="replenish_icon" />
                      <span>СБП</span>
                    </div>
                    <div
                      className={
                        "replenish_type " +
                        (payload.type === "card" ? "active" : "")
                      }
                      onClick={() => setPaymentType("card")}
                    >
                      <img src={card} alt="card" className="replenish_icon" />
                      <span>БАНКОВСКАЯ КАРТА</span>
                    </div>
                  </div>
                </div>
                {payload.sum > 0 && payload.sum && (
                  <span className="font-size-12 font-weight-semibold mb-px-20 mt-px-30">
                    К оплате:{" "}
                    {Utils.formatNumber(
                      payload.type === "card"
                        ? payload.sum / 0.97
                        : payload.sum / 0.99
                    )}{" "}
                    ₽
                  </span>
                )}
              </div>
              <span className="my-2 text-red-color font-size-12 text-start w-45">
                {payload.axiosError}
              </span>
              <div className="mt-auto">
                {isMobile && isSbpOpen ?
                    <SbpButton link={redirectSpbLink}/>
                :
                <button
                  className={
                    "site-btn big d-flex align-items-center justify-content-center"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    send();
                  }}
                >
                  Далее
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      d="M7.16602 12.5L11.166 8.5L7.16602 4.5"
                      stroke="#222222"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                            </svg>
                    </button>            }
                    </div>
            </>
          )}
                </div>
            </div>
        </>
  )
}

export default ReplenishMobile;