import './SbpButton.scss'
import sbplogo from "../../../images/personal-account/transactions/sbplogo.png";
// import logo from '../../../images'

export const SbpButton = ({link}:{link: string}) => {
  return (
    <a href={link} className='link'>
      <button
        className={
          "button"
        }
      >
        <span>Оплатить</span>
        <div className='logo-block'>
        <img src={sbplogo} alt="СБП" className="logo"/>
        <span className='logo-text'>СБП</span>
        </div>
      </button>
    </a>
  );
};
