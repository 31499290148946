import React from 'react';
import {CarTag} from "./CarTag";
import {CarRentDataInfo} from "../CardTypes";
import {CarDataType} from "../../../types/RentTypes";
import { format } from 'date-fns';

type CarRentTags = {
    car: CarRentDataInfo | CarDataType,
    tagStyle?: string
}

const formatDate = (text: string, preposition?: string) => {
    switch (text) {
        case format(new Date(), "dd.MM"):
          return "сегодня";
        case format(new Date(Date.now() + 24 * 60 * 60 * 1000), "dd.MM"):
          return preposition === "до" ? `${preposition} завтра` : "завтра";
        default:
          return preposition ? `${preposition} ${text}` : text;
      }
  };

const CarRentTags: React.FC<CarRentTags> = ({ car , tagStyle}) => {
    return (
        <>
            <CarTag
                small={true}
                className={tagStyle}
                type={car.available ? "free" : "not-free"}
                car={car}>
                {car.available
                    ? `Свободна ${car.available_at
                        ? formatDate(car.available_at as string, 'c') : ""}`
                    : `Занята ${car.available_at
                        ? formatDate(car.available_at as string, 'до') : ""}`}
            </CarTag>
            {
                 Array.isArray(car.tarif) && car.tarif.map((i, index: number) => (
                    <CarTag className={tagStyle} key={index} car={car}>
                        {i.Name}
                    </CarTag>
                ))
            }
        </>
    );
};

export default CarRentTags;