import React, { useState } from "react";
import CarStatBlock, { CarStatBlockItemMob } from "./CarStatBlock";
import { ICarData } from "../../../types/CatalogTypes";
import { CarData } from "../../common/CardTypes";

const CarInfo: React.FC<{
  car_data: ICarData;
  car: CarData;
  type: "mobile" | "descktop";
}> = (props) => {
  const [page, setPage] = useState("tech");
  return (
    <div className={"car-info "}>
      <div className={"car-info-btns car-info_detail"}>
        {props.car_data?.technical_parameters.length > 0 && (
          <button
            className={"car-info-btn " + (page === "tech" ? "active" : "")}
            onClick={() => {
              setPage("tech");
            }}
          >
            Тех. характеристики
          </button>
        )}
        {props.car_data?.equipment.length > 0 && (
          <button
            className={"car-info-btn " + (page === "standard" ? "active" : "")}
            onClick={() => {
              setPage("standard");
            }}
          >
            Комплектация
          </button>
        )}
      </div>
      <div className={"my-3"}>
        {page === "tech" && (
          <CarStatBlock
            type={props.type}
            column1Width={"2.5fr"}
            dotted={true}
            data={props.car_data?.technical_parameters}
          />
          // <CarStatBlockItemMob  data={props.car.tech} />
        )}
        {page === "standard" && (
          <CarStatBlock
            type={props.type}
            column1Width={"4fr"}
            data={props.car_data?.equipment}
          />
        )}
      </div>
    </div>
  );
};

export default CarInfo;
