import React from "react";
import classNames from "classnames";
import {CarDataType} from "../../../types/RentTypes";

export const CarTag: React.FC<{
    car: CarDataType | any;
    type?: "default" | "free" | "not-free" | 'without-bg';
    adaptive?: boolean
    style?: React.CSSProperties;
    className?: string;
    children: any;
    small?: boolean;
}> = ({car, type, small = "default", style, className, children = true, adaptive = true}) => {

    const tagClasses = classNames({
        "tag": true,
        "free": type == 'free',
        "not-free": type == 'not-free',
        "tag-without-background": type == 'without-bg',
        "tag-with-adaptive": adaptive,
        [className || ""]: Boolean(className)
    })

    return (
        <div
            className={tagClasses}
            style={style}
        >
            {children}
        </div>
    );
};
export const tags = ["Лизинг до 7 лет", "Аванс 0%"]