import React from "react";
import {CarCatalogDataInfo} from "../../types/CatalogTypes";
import {Link} from "react-router-dom";

import {CarDataInfo} from "./CardTypes";
import {CarDataType} from "../../types/RentTypes";

export const CarSameLink: React.FC<{
    car: CarDataInfo | CarCatalogDataInfo | CarDataType;
    style?: React.CSSProperties;
    className?: string;
    text?: string;
    responsive?: boolean;
    onClick?: () => void;
}> = ({
          car,
          style,
          className,
          text = "Посмотреть похожие модели",
          responsive,
          onClick,
      }) => {
    return (
        <Link
            to={`/catalog`}
            style={style}
            onClick={onClick}
            className={
                !responsive
                    ? "car__card-same  " + (className ?? "")
                    : "car__card-mobile-same  " + (className ?? "")
            }>
            <span>{text}</span>
            <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g id="Chevron Right">
                    <path
                        id="Shape"
                        d="M6.25 11.25L10 7.5L6.25 3.75"
                        stroke="#222222"
                        strokeWidth="1.57642"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </svg>
        </Link>
    );
};