import api from "../../core/axios";

class FinesService {
    getFines({page, endDate, startDate, isPaid}:{page: number, endDate: string, startDate: string, isPaid: string}) {
    const token = localStorage.getItem("accessToken");
    return api
      .get(`/voshod-auto/?w=fines&page=${page}&ViolationDateFrom=${startDate}&ViolationDateTo=${endDate}&status=${isPaid}`, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
        withCredentials: true,
      })
      .then((res) => res.data);
  }
}

export default new FinesService();
