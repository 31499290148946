import axios from "axios";

class TransferService {
  newTransfer({ from, to, summ }: { from: string; to: string; summ: string }) {
    const token = localStorage.getItem("accessToken");
    return axios
      .get(
        `https://taxivoshod.ru/api/voshod-auto/?w=transfer-account&summ=${summ}&to=${to}&from=${from}`,
        {
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {},
          withCredentials: true,
        }
      )
      .then((res) => res.data);
  }
}

export default new TransferService();
