import styled from "styled-components";
import { ToastContainer } from "react-toastify";

export const ToastContainerStyled = styled(ToastContainer)<{ error?: boolean }>`
  &&&Toastify__toast-theme--colored,
  .Toastify__toast--error {
    height: 62px;
    width: 400px;
    background: #bf3535 !important;
    padding: 10px 20px 10px 15px;
  }
  &&&Toastify__toast-theme--colored,
  .Toastify__toast--success {
    height: 62px;
    width: 400px;
    background: #008f4b !important;
    padding: 10px 20px 10px 15px;
  }
  &&&Toastify__toast-theme--colored,
  .Toastify__toast-body {
    transform: translateY(-4px);
  }
`;

export const ToastMessage = styled.div`
  color: #fff;
`;

export const MessageInner = styled.div``;

export const ToastMessageFirst = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  color: #fff;
  margin-bottom: 5px;
  padding-left: 20px;
  @include l-sm {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 13.79px;
  }
`;

export const ToastMessageSecond = styled.div`
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  padding-left: 20px;
  @include l-sm {
    font-family: Inter;
    font-size: 10px;
    font-weight: 300;
    line-height: 11.49px;
  }
`;
