import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const ProtectedRoutes = ({ children, redirectPath = "/personal-account" }) => {
  const user = useAuth();

  if (user.employee !== true) {
    return <Navigate to={redirectPath} replace={true} />;
  } else {
    return children;
  }
};
