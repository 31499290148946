import React, { useEffect, useState } from "react";
import BaseLayout, { MetaTags } from "../layout/BaseLayout";
import { Col, Container, Row } from "react-bootstrap";
import { useLoaderData, useParams } from "react-router-dom";
import Api from "../../Api";

import CarBase from "./Car/CarBase";
import CarImages from "./Car/CarImages";
import CarInfo from "./Car/CarInfo";
import LoadError from "../common/LoadError";

import { CarDetailLayout } from "../layout/CarDetailLayout";
import { useQuery } from "@tanstack/react-query";
import catalogService from "../../api-functions/catalog-page/catalog-service";
import Loader from "../common/Loader";
import MetaDecorator from "../layout/MetaDecorator";
import { CarData } from "../common/CardTypes";

const CarPage = () => {
  const car = useLoaderData() as CarData;
  const { id } = useParams();
  const { data, isLoading, error, isError } = useQuery({
    queryKey: [`catalog-car-${id}`, id],
    queryFn: () => catalogService.getOneCar(id),
  });
  if (isLoading) return <Loader />;

  const title =
    data?.item?.brand +
    " " +
    data?.item?.model +
    " - " +
    process.env.REACT_APP_WEBSITE_NAME;
  const meta: MetaTags = {
    description:
      data.item.brand + " " + data.item.model + " в лизинг или аренду",
    keywords: `аренда, лизинг,${data.item.brand} ${data.item.model}, ${data.item.brand}, ${data.item.model}`,
  };

  return (
    <>
      <MetaDecorator
        title={title}
        url={`/catalog/${id}`}
        image={data?.item?.images[0].image}
      />
      <CarDetailLayout
        meta={meta}
        title={title}
        headerSelectedLink={"/catalog"}
        backLink={`/catalog/`}
        footerSmall
      >
        <Container
          fluid={"xxl"}
          className={"d-none d-lg-block car-page pt-px-20"}
        >
          <LoadError response={data} />
          {!isError && (
            <Row className={"gx-5"}>
              <Col lg={6} className={"d-none d-lg-block"}>
                <CarImages car={data.item} />
                <CarInfo car_data={data.item} type={"descktop"} car={data.item} />
              </Col>
              <Col lg={6}>
                <div className={"sticky-no-scrollbar top100 "}>
                  <div className={"d-block d-lg-none"}>
                    <CarImages car={data.item} />
                  </div>
                  <CarBase car_data={data.item} car={data.item} />
                </div>
              </Col>
            </Row>
          )}
        </Container>
        <div className={"d-block d-lg-none"}>
          <Container fluid={"xxl"}>
            <CarImages car={data.item} />
          </Container>
          <CarBase car_data={data.item} car={data.item} />
          <CarInfo car_data={data.item} type={"mobile"} car={data.item} />
        </div>
      </CarDetailLayout>
    </>
  );
};

const carDataLoader = async ({ request, params }) => {
  return Api.car(params.id);
};
export { carDataLoader };
export default CarPage;
