import { toast } from "react-toastify";
import * as S from "./AppToast.styles";
import { createPortal } from "react-dom";
import icon from "./icon-success.svg";
import close from "./icon-close.svg";
import "react-toastify/dist/ReactToastify.css";
import { injectStyle } from "react-toastify/dist/inject-style";

type RunToast = {
  firstMessage: any;
  secondMessage: any;
  toastId: string;
};

if (typeof window !== "undefined") {
  injectStyle();
}

const CloseButton = ({ closeToast}) => (
   <img src={close} alt="close" width={18} height={18}/>
)

const AppToast = () => {
  return createPortal(
    <S.ToastContainerStyled
      error={true}
      hideProgressBar
      position={"bottom-center"}
      autoClose={3000}
      closeButton={CloseButton}
    />,
    document.body
  );
};

export const runSuccessToast = ({
  firstMessage,
  secondMessage,
  toastId,
}: RunToast) => {
  return toast.success(
    <S.MessageInner>
      <S.ToastMessageFirst>{firstMessage}</S.ToastMessageFirst>
      <S.ToastMessageSecond>{secondMessage}</S.ToastMessageSecond>
    </S.MessageInner>,
    {
      toastId,
      icon: <img src={icon} alt="icon" />,
    }
  );
};

export const runErrorToast = ({
  firstMessage,
  secondMessage,
  toastId,
}: RunToast) => {
  return toast.error(
    <S.MessageInner>
      <S.ToastMessageFirst>{firstMessage}</S.ToastMessageFirst>
      <S.ToastMessageSecond>{secondMessage}</S.ToastMessageSecond>
    </S.MessageInner>,
    {
      toastId,
      icon: <img src={icon} alt="icon" />,
    }
  );
};

export default AppToast;
