import api from "../../core/axios";

class LeasingService {
  getLeasingCars() {
    const token = localStorage.getItem("accessToken");
    return api
      .get("/voshod-auto/?w=car-leasing", {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
        withCredentials: true,
      })
      .then((res) => res.data);
  }
}

export default new LeasingService();
