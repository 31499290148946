import React from "react";
import {CarCatalogDataInfo} from "../../../types/CatalogTypes";
import {Link, useNavigate} from "react-router-dom";
import Utils from "../../../utils/Utils";
import {CarPreorderButton} from "../CarPreorderButton";
import {CarTag, tags} from "../CarTag/CarTag";
import {CarRentDataInfo} from "../CardTypes";
import ImageDoesNotExist from "../ImageDoesNotExists/ImageDoesNotExists";
import CarRentTags from "../CarTag/CarRentTags";



const CarCardMobile: React.FC<{
    car: CarCatalogDataInfo | CarRentDataInfo;
    type: "rent" | "installment"
    id?: string;
}> = ({car, id,type}) => {
    const navigate = useNavigate();

    const isInstallment = type === "installment";
    const isRent = type === "rent";

    return (
        <div
            className="car__card-mobile anim-start-top-4 anim-duration-1800"
            id={id}
        >
            {isInstallment
                ?
                <Link to={`/catalog/${car.id}`} style={{ textDecoration: "none"}} className={`${car.image ? "car__card-image-mobile" : ""}`}>
                    {
                        car.image
                            ? <img
                                src={car.image} alt={car.brand + " " + car.model}/>
                            :
                            <div className={"car__card-image-mobile--image-does-not-exists-wrapper"}>
                                <ImageDoesNotExist/>
                            </div>
                    }
                </Link>
                :
                <div className={`${car.image ? "car__card-image-mobile" : ""}`}>
                    {
                        car.image
                        ?   <img
                                src={car.image} alt={car.brand + " " + car.model}
                            />
                        :
                            <div className={"car__card-image-mobile--image-does-not-exists-wrapper"}>
                                <ImageDoesNotExist />
                            </div>
                    }
                </div>

            }
            <div className="car__card-description-mobile">
                <div className={`car__card-taglist-mobile ${isInstallment && "car__card-taglist-mobile--change-order"}`}>
                    {
                        isRent && <CarRentTags car={car as CarRentDataInfo} />
                    }
                    {
                        isInstallment && tags.map((i, index) => (
                            <CarTag key={index} car={car} type={"without-bg"}>
                                {i}
                            </CarTag>
                        ))
                    }
                </div>
                <div
                    onClick={() => navigate(`/catalog/${car.id}`)}
                    className="car__card-title-mobile"
                    title={`${car.brand} ${car.model}`}
                >
                    {car.brand} <span className={"model"}>{car.model}</span>
                </div>
                <div className={"car__card-regnum-mobile"}>
                    {car?.regnum}
                </div>
                <div className="car__card-devider-mobile"></div>

                <div className="car__card-payment-mobile">
                    от &nbsp;
                    <span className="car__card-payment-mobile-value">
                        {Utils.numberWithSpaces(isRent ? (car as CarRentDataInfo).rentpay  : (car as CarCatalogDataInfo).min_pay)} ₽
                    </span>
                    &nbsp; / сут
                </div>
                <div
                    className={`car__card-price-mobile ${isRent && "car__card-price-mobile--without-mg"}`}
                >
                    {isRent ? "Депозит" : "Цена"} от&nbsp;
                    <span
                        className="car__card-price-value-mobile"
                    >
						{Utils.numberWithSpaces(isRent ? (car as CarRentDataInfo).deposit  : (car as CarCatalogDataInfo).price)} ₽
					</span>
                </div>
                <div className="car__card-preorder-mobile">
                    {isInstallment && <CarPreorderButton car={car as CarCatalogDataInfo}/> }
                    {isRent && <button className={"site-btn big"}>Забронировать</button> }
                </div>
            </div>
        </div>
    );
};
export default CarCardMobile;