import api from "../../core/axios";

export default class RaitingService {
  static getRaitingUsers(date?: string) {
    const token = localStorage.getItem("accessToken");
    const url = date
      ? `/voshod-auto/?w=rating-order&date=${date}`
      : "/voshod-auto/?w=rating-order";
    return api
      .get(url, {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
        withCredentials: true,
      })
      .then((res) => res.data);
  }
}
