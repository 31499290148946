import React, {useEffect, useState} from 'react';
import RentItemLayout from "../../../layout/RentItemLayout";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import RentService from "../../../../api-functions/rent-page/rent-service";
import styles from "./RentItemPage.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import CustomCarousel from "../../../common/CustomCarousel/CustomCarousel";
import CustomBackButton from "../../../common/CustomBackButton/CustomBackButton";
import CarRentTags from "../../../common/CarTag/CarRentTags";
import {RentBookingPaymentStatus} from "../../../../types/RentTypes";
import {useAuth} from "../../../../hooks/useAuth";
import {CarBookingStepsType} from "../../../common/CarRentForm";
import api from "../../../../core/axios";
import {AxiosError} from "axios";
import {ErrorResponse} from "../../../../Api";
import {BrowserView, MobileView} from "react-device-detect";
import RentCarDetailModal from "../../../common/RentCarDetailModal";
import {RentModalMobile} from "../../../common/Rent/RentModalMobile/RentModalMobile";
import Utils from "../../../../utils/Utils";
import Loader from "../../../common/Loader";
import {MetaTags} from "../../../layout/BaseLayout";
import MetaDecorator from "../../../layout/MetaDecorator";
import {Container} from "react-bootstrap";

const RentItemPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [carFullName, setCarFullName] = useState<null | string>(null);
    const [paymentStatus, setPaymentStatus] =
        useState<RentBookingPaymentStatus>(null);
    const { isAuthenticated, has_profile, user_status } = useAuth();
    const [step, setStep] = useState<CarBookingStepsType>("start");
    const [depositPrice, setDepositPrice] = useState(0);
    const [errorMessage, setErrorMessage] = useState<null | string>(null);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
    const { carID, id: pageId} = useParams();
    const {data,isLoading, isSuccess} = useQuery({
        queryKey: [`rent-car-${carID}`],
        queryFn: async () =>  await RentService.getOneCarPromise(carID),
    })

    const onOpenModalClick = () => {
        if (isAuthenticated && has_profile) {
            (async () => {
                await getPriceCar();
            })()
        } else if (!has_profile && user_status === "need_auth") {
            setStep("start");
        } else if (isAuthenticated && !has_profile) {
            setStep("create");
        }
        setIsModalOpen(true);
    }

    useEffect(() => {
        checkCardPayment();
    }, [location]);

    const backFunc = (isOpen: boolean) => {
        if(isOpen) {
            setIsModalOpen(false)
        } else {
            navigate(`/rent/page/${pageId}`)
        }
    }

    const checkCardPayment = () => {
        if (!location.state) {
            return;
        }

        if (location.state.status === "success") {
            setPaymentStatus(location.state.payment_status);
            setCarFullName(location.state.car_name);
            setStep("booking_result");
            setIsModalOpen(true);
        } else {
            setPaymentStatus("CANCELLED");
            setStep("booking_result");
        }
    };

    const getPriceCar = async () => {
        setIsModalLoading(true);
        try {
            const res = await api.get(`/voshod-auto/?w=book-a-car&id=${carID}`, {
                withCredentials: true,
            });
            if (res.data.result === 1) {
                setDepositPrice(res.data.summ);
                if (res.data.summ > 0) setStep("payment");
                else setStep("finish");
            }
        } catch (error) {
            setIsModalOpen(false);
            setErrorMessage(
                (error as AxiosError<ErrorResponse>).response?.data.message ??
                "Возникла ошибка с сервером поробуйте позже"
            );
        } finally {
            setIsModalLoading(false);
        }
    };


    // const checkStepsMobile = async () => {
    //     if (!isAuthenticated && !has_profile) {
    //         setStep("start");
    //         setIsModalOpen(true);
    //     } else if (isAuthenticated && has_profile) {
    //         await getPriceCar();
    //     } else if (isAuthenticated && !has_profile) {
    //         setStep("create");
    //         setIsModalOpen(true);
    //     }
    // };

    if(isLoading) {
        return <Loader />
    }

    const car = data?.data.item;

    const title = `${car?.brand} ${car?.model} - ${process.env.REACT_APP_WEBSITE_NAME}`;
    const meta: MetaTags = {
        description: car?.brand + " " + car?.model + " в лизинг или аренду",
        keywords: `аренда, лизинг,${car?.brand} ${car?.model}, ${car?.brand}, ${car?.model}`,
    };

    return (
        <>
            <MetaDecorator
                title={title}
                url={`/rent/page/${pageId}/car/${carID}`}
                image={car?.images[0].image}
            />
            <RentItemLayout
                backFunc={() => backFunc(isModalOpen)}
                headerSelectedLink={"/rent/page/1"}
                title={title}
                meta={meta}
            >
                <Container
                    className={styles.containerXxl}
                    fluid={"xxl"}
                >
                <div className={styles.rentItemPage}>
                    <div className={styles.rentItemCarGallery}>
                        <CustomBackButton className={styles.backButton}>
                            <FontAwesomeIcon icon={faAngleLeft} size={"sm"}/>
                            &nbsp;&nbsp;Вернуться в каталог
                        </CustomBackButton>
                        {
                            car &&
                            <CustomCarousel images={car.images}/>
                        }
                    </div>
                    {
                        car && (
                            <div className={styles.rentItemCarInfo}>
                                <h2 className={styles.rentItemCarBrand}>
                                    {car.brand}
                                    <span className={styles.rentItemCarModel}> {car.model}</span>
                                </h2>
                                <div className={styles.rentItemCarRegnum}>
                                    {car.regnum}
                                </div>
                                <div className={styles.rentItemCarTagsWrapper}>
                                    <CarRentTags tagStyle={styles.rentItemCarTagsWrapperTagStyles} car={car}/>
                                </div>
                                <div className={styles.rentItemCarRentPay}>
                                    <div>Стоймость:</div>
                                    <div><span>{car.rentpay} ₽</span> / сут</div>
                                    <div>{car.rent_description}</div>
                                    <div>Депозит от <span>{car.deposit} ₽</span></div>
                                </div>
                                {
                                    (car.available || car.available_at) && (
                                        <div className={styles.rentItemCarButtonWrapper}>
                                            <button onClick={onOpenModalClick} className={"site-btn big"}>
                                                Забронировать
                                            </button>
                                        </div>
                                    )
                                }
                                <div className={styles.rentItemCarTarrifsWrapper}>
                                    <h3>Тариф</h3>
                                    {Array.isArray(car.rentarr) && car.rentarr.map((item, index) => (
                                        <div key={index}>
                                            <span>{item.field} </span>
                                            <span>{Utils.numberWithSpaces(item.sum)} ₽ / </span>
                                            <span>{item.ratio}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.rentCarPriceWarning}>Цены актуальны при работе в такси. <br></br> 
                                Цены на аренду для личных целей можно уточнить по телефону   
                                <a href="tel:88123176815"> +78123176815</a></div>
                                <div className={styles.rentItemCarInfoWrapper}>
                                    <h3>Информация</h3>
                                    <div>
                                        <span>Год</span>
                                        <span>{car.year}</span>
                                    </div>
                                    <div>
                                        <span>КПП</span>
                                        <span>{car.kpp}</span>
                                    </div>
                                    <div>
                                        <span>Пробег</span>
                                        <span>{car.run} км</span>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {isModalOpen && (
                        <>
                            <BrowserView>
                                <RentCarDetailModal
                                    isModalLoading={isModalLoading}
                                    closeModal={setIsModalOpen}
                                    errorMessage={errorMessage}
                                    step={step}
                                    setStep={setStep}
                                    paymentStatus={paymentStatus}
                                    setPaymentStatus={setPaymentStatus}
                                    getPriceCar={getPriceCar}
                                    depositPrice={depositPrice}
                                    setDepositPrice={setDepositPrice}
                                    setCarName={setCarFullName}
                                    carName={carFullName}
                                />
                            </BrowserView>
                            <MobileView>
                                {isSuccess && car ? (
                                    <>
                                        <RentModalMobile
                                            step={step}
                                            setStep={setStep}
                                            active={isModalOpen}
                                            setActive={setIsModalOpen}
                                            car={car}
                                            depositPrice={depositPrice}
                                            getPriceCar={getPriceCar}
                                            setDepositPrice={setDepositPrice}
                                            paymentStatus={paymentStatus}
                                            setPaymentStatus={setPaymentStatus}
                                            carName={carFullName}
                                            setCarName={setCarFullName}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <h3>Машина не найдина, повторите попытку позжe</h3>
                                    </>
                                )}
                            </MobileView>
                        </>
                    )}
                </div>
                </Container>
            </RentItemLayout>
        </>
    );
};

export default RentItemPage;


